<template>
  <div>
    <FormGroup id="name" v-model="form.name" type="text" :disabled="disabled" label="Name" />
    <FormGroup id="email" v-model="form.email" type="text" :disabled="disabled" label="Email" />
    <FormGroup id="role" v-model="form.role" type="select" :disabled="disabled" label="Role" :options="sortedRoles" />

    <label>Entities</label>
    <p>Restrict this user to accessing the selected entities. They will have access to all assets and accounts within this entity.</p>
    <FormGroup id="entity" v-model="form.entityId" type="select-array-search" :disabled="disabled" label="" :options="sortedEntities" />

    <label>Assets</label>
    <p>Restrict this user to accessing the selected entities. They will have access to all accounts within this asset.</p>
    <FormGroup id="asset" v-model="form.assetId" type="select-array-search" :disabled="disabled" label="" :options="assets" />

    <label>Accounts/Meters</label>
    <p>Restrict this user to accessing the selected accounts only</p>
    <FormGroup id="account" v-model="form.accountId" type="select-array-search" :disabled="disabled" label="" :options="accounts" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Form from '@/components/forms/Form';
import FormGroup from '@/components/FormGroup';

export default {
  name: 'UserForm',
  components: {
    FormGroup
  },
  extends: Form,
  computed: {
    ...mapGetters({
      entities: 'entity/entities',
      roles: 'company/roles',
      loadingAction: 'role/loadingAction'
    }),
    sortedEntities() {
      const topLevelEntities = this.entities.filter(entity => entity.ultimateParentEntityId === entity._id);
      const allOtherEntities = this.entities.filter(entity => entity.ultimateParentEntityId !== entity._id);

      return [...topLevelEntities, ...allOtherEntities];
    },
    sortedRoles() {
      return this.roles.map(role => ({ label: role.description, value: role.name }));
    },
    currentRole() {
      const activeCompany = this.value.companies.find(company => company.companyId === this.value.companyId);

      return activeCompany ? activeCompany.role : null;
    }
  },
  async mounted() {
    await this.getRoles();

    this.entityRoleIds = this.roles.filter(role => role.name.startsWith('entity')).map(role => role.id);
    this.form = {
      ...this.value,
      role: this.value.currentCompany.role
    };

    this.listEntities({ data: { params: { companyId: this.$route.params.id, $sort: 'legalName:1' } } });
  },
  methods: {
    ...mapActions({
      listEntities: 'entity/list',
      getRoles: 'company/getRoles'
    })
  }
};
</script>
