var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FormGroup', {
    attrs: {
      "id": "name",
      "type": "text",
      "disabled": _vm.disabled,
      "label": "Name"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "email",
      "type": "text",
      "disabled": _vm.disabled,
      "label": "Email"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "role",
      "type": "select",
      "disabled": _vm.disabled,
      "label": "Role",
      "options": _vm.sortedRoles
    },
    model: {
      value: _vm.form.role,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "role", $$v);
      },
      expression: "form.role"
    }
  }), _c('label', [_vm._v("Entities")]), _c('p', [_vm._v("Restrict this user to accessing the selected entities. They will have access to all assets and accounts within this entity.")]), _c('FormGroup', {
    attrs: {
      "id": "entity",
      "type": "select-array-search",
      "disabled": _vm.disabled,
      "label": "",
      "options": _vm.sortedEntities
    },
    model: {
      value: _vm.form.entityId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "entityId", $$v);
      },
      expression: "form.entityId"
    }
  }), _c('label', [_vm._v("Assets")]), _c('p', [_vm._v("Restrict this user to accessing the selected entities. They will have access to all accounts within this asset.")]), _c('FormGroup', {
    attrs: {
      "id": "asset",
      "type": "select-array-search",
      "disabled": _vm.disabled,
      "label": "",
      "options": _vm.assets
    },
    model: {
      value: _vm.form.assetId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "assetId", $$v);
      },
      expression: "form.assetId"
    }
  }), _c('label', [_vm._v("Accounts/Meters")]), _c('p', [_vm._v("Restrict this user to accessing the selected accounts only")]), _c('FormGroup', {
    attrs: {
      "id": "account",
      "type": "select-array-search",
      "disabled": _vm.disabled,
      "label": "",
      "options": _vm.accounts
    },
    model: {
      value: _vm.form.accountId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "accountId", $$v);
      },
      expression: "form.accountId"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }